@import "~neptune-react-core/src/assets/stylesheets/colors.scss";
@import "~neptune-react-core/src/assets/stylesheets/flexbox.scss";
@import "~neptune-react-core/src/assets/stylesheets/fonts.scss";
@import "~neptune-react-core/src/assets/stylesheets/mixins.scss";

.FileCommentsUtilMenu {
  @extend .flex, .col, .full;
  height: 100%;
  position: relative;
}

.FileComments {
  height: 100%;
  overflow-y: scroll;
}

.Comment {
  @extend .flex, .col, .start;
  align-items: flex-start;
  margin: 0.5em 0 1.5em;
}

.Comment__header {
  @extend .flex, .row, .pure, .full;
  margin-bottom: 0.5em;
}

.Comment__identity {
  @extend .flex, .col, .center;
  align-items: flex-start;
  margin-left: 1em;

  .Comment__name, .Comment__time {
    line-height: 1.2;
  }

  .Comment__name {
    color: $primary-dark;
    font-weight: 600;
    text-align: left;
  }

  .Comment__time {
    color: $grey;
    font-size: 0.75em;
    font-weight: 500;
  }
}

.Comment__avatar {
  @extend .flex;
  background: $grey-light;
  border-radius: 2em;
  height: 2em;
  overflow: hidden;
  width: 2em;

  img {
    width: 100%;
  }
}

.Comment__content {
  @extend .flex, .start;
  background: $grey-lightest;
  border-radius: 1em;
  color: darken($grey, 35%);
  font-size: 0.9em;
  line-height: 1.2;
  padding: 1em;
  text-align: left;
}

.UserComment {
  @extend .flex, .full;
  position: sticky;
  bottom: 0;

  .UserComment__title {
    @extend .flex, .row, .start, .pure;
    background: $white;
    padding: 1em 0;
  }

  .UserComment__name {
    color: $primary-dark;
    font-weight: 500;
    margin-left: 1em;
  }

  .UserComment__avatar {
    width: 1.5em;
    height: 1.5em;
  }

  .UserComment__comment {
    position: relative;
  }

  .Comment__submit {
    margin-top: 0;
    letter-spacing: 1px;
  }

  #UserComment__content {
    font-family: $base-font;
    max-height: 5em;
    min-height: 1em;
    resize: none;
    padding: 1em;
    width: 100%;
  }
}
