@import "~neptune-react-core/src/assets/stylesheets/_colors.scss";
@import "~neptune-react-core/src/assets/stylesheets/_mixins.scss";
@import "~neptune-react-core/src/assets/stylesheets/_flexbox.scss";

#SupportData {
  @extend .flex, .col, .full;
  overflow: scroll;

  h1, h2 {
    margin: 0;
  }

  h1 {
    border-bottom: 2px solid $grey-light;
    color: $grey-darkest;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  h2 {
    color: $grey;
    color: var(--blue);
    font-size: 1em;
    margin-bottom: 1em;
  }

  .SupportArticle {
    @extend .flex, .col;
    margin-bottom: 2em;

    .SupportArticle__content {
      @extend .flex, .col;
      @include transition(all 0.1s ease);
      max-height: 0;
      height: auto;
      overflow: hidden;
      // opacity: 0;
    }

    &.active {
      .SupportArticle__content {
        max-height: none;
        // opacity: 1;
      }
    }
  }

  .ArticleTitle {
    @extend .flex, .row, .start, .pure;
    font-size: 1.25em;
    margin-bottom: 1em;

    svg {
      @include transition(all 0.1s ease);
      height: 1em;
      width: 1em;
      margin-left: 1em;
    }
  }

  .SupportArticle.active {
    svg {
      transform: rotate(90deg);
    }
  }
}

@include desk-xxs {
  #SupportData {
    .SupportArticles {
      padding: 2em;
    }
  }
}
