@import "~neptune-react-core/src/assets/stylesheets/_breakpoints.scss";
@import "~neptune-react-core/src/assets/stylesheets/_colors.scss";
@import "~neptune-react-core/src/assets/stylesheets/_flexbox.scss";

#SignaturePage .PageContent {
  background: transparent;
  border-radius: 0;
  overflow-y: scroll;
  padding: 1em;
  width: 100%;
}

@include tab-lg {
  .Sentiment__col, .Concerns__col {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-right: 2em;
    }
  }
}

@include desk-sm {
  .Sentiment__col, .Concerns__col {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
}


@include desk-xxs {
  #SignaturePage .PageContent {
    padding: 0;
  }
}
