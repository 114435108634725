@import "~neptune-react-core/src/assets/stylesheets/breakpoints.scss";
@import "~neptune-react-core/src/assets/stylesheets/colors.scss";
@import "~neptune-react-core/src/assets/stylesheets/flexbox.scss";

.ProfileCards {
  @extend .flex, .col, .center;
  flex: 1;

  .PageCardWrapper, .PageCard {
    height: 100%;
  }

  .PageCard {
    @extend .flex, .col, .start;
    align-items: flex-start;
    width: 100%;

    h2 {
      color: $grey-darkest;
      margin-top: 0;
      font-weight: 500;
    }
  }
}

@include desk-xxs {
  .PageCard {
    &:first-of-type {
      // margin-right: 2em;
    }

    &:last-of-type {
      // margin-right: 2em;
    }
  }
}
