@import "~neptune-react-core/src/assets/stylesheets/breakpoints.scss";
@import "~neptune-react-core/src/assets/stylesheets/flexbox.scss";

.SettingsCards {
  @extend .flex, .row, .center;
  flex: 1;

  .PageCardWrapper, .PageCard {
    height: 100%;
  }

  .PageCard {
    @extend .flex, .col, .between;
    align-items: flex-start;
    width: 100%;
  }
}

.Logout__container {
  width: 100%;
  max-width: 20em;
}

@include desk-xxs {
  .PageCard {
    &:first-of-type {
      margin-right: 2em;
    }

    &:last-of-type {
      margin-right: 2em;
    }
  }
}
