@import "~neptune-web-core/src/assets/stylesheets/breakpoints.scss";
@import "~neptune-web-core/src/assets/stylesheets/colors.scss";
@import "~neptune-web-core/src/assets/stylesheets/flexbox.scss";
@import "~neptune-web-core/src/assets/stylesheets/mixins.scss";
@import "~neptune-web-core/src/components/layouts/Footer/vars.scss";
@import "~neptune-web-core/src/components/layouts/Menu/vars.scss";
@import "~neptune-web-core/src/components/layouts/UtilityMenu/vars.scss";
@import "./_vars.scss";

.App {
  @extend .flex, .row, .center;
  height: $view-height-mobile-with-menu;
  // padding-bottom: $menu-height-mobile;

  &.no-menu {
    height: $view-height-mobile;
  }
}

.Layout__container {
  background: $grey-lightest;
  height: $view-height-mobile-with-menu;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.no-menu {
    height: $view-height-mobile;
  }
}

main#Layout {
  height: $view-height-mobile-with-menu;
  z-index: 1;

  &.no-menu {
    height: $view-height-mobile;
  }
}

@include desk-xxs {
  .App {
    height: $view-height-desktop;
    padding-bottom: 0;
  }

  .Layout__container {
    height: $view-height-desktop;
    // max-width: calc(100vw - #{$menu-width-desktop} - #{$utility-menu-width-desktop} - 2rem - 2rem);
    // max-width: calc(100vw - 80px - 400px);
    // max-width: calc(100vw - #{$utility-menu-width-desktop});
  }

  main#Layout {
    height: $view-height-desktop;
  }
}
