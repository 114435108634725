@import "~neptune-react-core/src/assets/stylesheets/_breakpoints.scss";
@import "~neptune-react-core/src/assets/stylesheets/_colors.scss";
@import "~neptune-react-core/src/assets/stylesheets/_flexbox.scss";
@import "~neptune-web-core/src/assets/stylesheets/mixins.scss";

#ExternalitiesPage {
  .PageContent {
    overflow-y: scroll;
    box-shadow: 0 0 10px -5px $grey;
  }
}
