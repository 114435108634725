@import "~neptune-web-core/src/assets/stylesheets/flexbox.scss";
@import "~neptune-web-core/src/assets/stylesheets/mixins.scss";

#NotFoundPage {
  .PageContent {
    @extend .flex, .col, .center;
  }

  h1 {
    @include titleize;
  }
}
